import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */
/* @jsx mdx */
import DefaultLayout from "/app/src/layout.tsx";
export const _frontmatter = {};
const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};
const Content = makeShortcode("Content");
const InstagramEmbed = makeShortcode("InstagramEmbed");
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <Content mdxType="Content">
      <p>{`Zažite jedinečnú jazdu na horskej trojkolke a ponorte sa do krásy lyžiarskeho strediska Vernár Studničky. Bez ohľadu na to, či ste začiatočník alebo adrenalínový nadšenec, naše trojkolky ponúkajú perfektný zážitok na mieru.`}</p>
      <p>{`Lyžiarsky vlek vás vyvezie na vrchol zjazdovky dlhej 300 metrov s miernym sklonom, ideálnej pre bezpečnú a zábavnú jazdu na trojkolkách. Na svahu môžu byť naraz maximálne 4 trojkolky, čo zaručuje dostatok priestoru pre každého jazdca.`}</p>
      <p>{`Naše horské trojkolky sú vybavené dvoma nezávislými brzdami na každom kolese, čo zaručuje absolútnu kontrolu a bezpečnosť. Predajte sa pocitu slobody, keď sa spustíte dolu zjazdovkou, kde každý manéver máte plne pod kontrolou.`}</p>
      <p>{`Jazda na horských trojkolkách je odporúčaná pre osoby od 15 rokov. Mladší jazdci môžu ísť v tandeme s dospelým, čo umožňuje aj rodinám s deťmi zažiť toto dobrodružstvo spoločne. V cene jazdy je zahrnuté aj požičanie prilby, aby bola vaša bezpečnosť vždy na prvom mieste.`}</p>
      <h2>{`Cenník`}</h2>
      <table border="0" cellSpacing="0" cellPadding="0">
	<tbody><tr>
		<td style={{
              paddingRight: 20
            }}>1 jazda</td>
		<td>3 €</td>
	</tr><tr>
		<td>5 jázd</td>
		<td>14 €</td>
	</tr><tr>
		<td>10 jázd</td>
		<td>27 €</td>
	</tr></tbody>
      </table>
      <h2>{`Galéria`}</h2>
      <p><span parentName="p" {...{
          "className": "gatsby-resp-image-wrapper",
          "style": {
            "position": "relative",
            "display": "block",
            "marginLeft": "auto",
            "marginRight": "auto",
            "maxWidth": "1200px"
          }
        }}>{`
      `}<span parentName="span" {...{
            "className": "gatsby-resp-image-background-image",
            "style": {
              "paddingBottom": "66.66666666666666%",
              "position": "relative",
              "bottom": "0",
              "left": "0",
              "backgroundImage": "url('data:image/jpeg;base64,/9j/2wBDABALDA4MChAODQ4SERATGCgaGBYWGDEjJR0oOjM9PDkzODdASFxOQERXRTc4UG1RV19iZ2hnPk1xeXBkeFxlZ2P/2wBDARESEhgVGC8aGi9jQjhCY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2P/wgARCAANABQDASIAAhEBAxEB/8QAFwAAAwEAAAAAAAAAAAAAAAAAAAMEAv/EABYBAQEBAAAAAAAAAAAAAAAAAAEAAv/aAAwDAQACEAMQAAABlZNjFQLB/8QAGRAAAwEBAQAAAAAAAAAAAAAAAAECMQMQ/9oACAEBAAEFAk6F0pDu/HuH/8QAFhEBAQEAAAAAAAAAAAAAAAAAABEB/9oACAEDAQE/AZiP/8QAFhEAAwAAAAAAAAAAAAAAAAAAAAES/9oACAECAQE/AaZTP//EABgQAAIDAAAAAAAAAAAAAAAAAAARICFB/9oACAEBAAY/AsKUf//EABkQAAMBAQEAAAAAAAAAAAAAAAABIRFRYf/aAAgBAQABPyGGuegDLiSE9NRpwj//2gAMAwEAAgADAAAAEIzP/8QAFxEAAwEAAAAAAAAAAAAAAAAAAAERMf/aAAgBAwEBPxBUwg//xAAWEQEBAQAAAAAAAAAAAAAAAAABEDH/2gAIAQIBAT8QG1h//8QAGxABAAMBAAMAAAAAAAAAAAAAAQARITFBUWH/2gAIAQEAAT8QCW8LjFzUSUKS9gerGfQZ0YKSwsc7cXAUT//Z')",
              "backgroundSize": "cover",
              "display": "block"
            }
          }}></span>{`
  `}<picture parentName="span">{`
          `}<source parentName="picture" {...{
              "srcSet": ["/static/f02c42ea91577e9d93326c812e2de73b/e93cc/IMG_7456.webp 300w", "/static/f02c42ea91577e9d93326c812e2de73b/b0544/IMG_7456.webp 600w", "/static/f02c42ea91577e9d93326c812e2de73b/68fc1/IMG_7456.webp 1200w", "/static/f02c42ea91577e9d93326c812e2de73b/a2303/IMG_7456.webp 1800w", "/static/f02c42ea91577e9d93326c812e2de73b/4293a/IMG_7456.webp 2400w", "/static/f02c42ea91577e9d93326c812e2de73b/45587/IMG_7456.webp 5616w"],
              "sizes": "(max-width: 1200px) 100vw, 1200px",
              "type": "image/webp"
            }}></source>{`
          `}<source parentName="picture" {...{
              "srcSet": ["/static/f02c42ea91577e9d93326c812e2de73b/73b64/IMG_7456.jpg 300w", "/static/f02c42ea91577e9d93326c812e2de73b/3ad8d/IMG_7456.jpg 600w", "/static/f02c42ea91577e9d93326c812e2de73b/6c738/IMG_7456.jpg 1200w", "/static/f02c42ea91577e9d93326c812e2de73b/8b34c/IMG_7456.jpg 1800w", "/static/f02c42ea91577e9d93326c812e2de73b/111a0/IMG_7456.jpg 2400w", "/static/f02c42ea91577e9d93326c812e2de73b/a8943/IMG_7456.jpg 5616w"],
              "sizes": "(max-width: 1200px) 100vw, 1200px",
              "type": "image/jpeg"
            }}></source>{`
          `}<img parentName="picture" {...{
              "className": "gatsby-resp-image-image",
              "src": "/static/f02c42ea91577e9d93326c812e2de73b/6c738/IMG_7456.jpg",
              "alt": "IMG 7456",
              "title": "IMG 7456",
              "loading": "lazy",
              "decoding": "async",
              "style": {
                "width": "100%",
                "height": "100%",
                "margin": "0",
                "verticalAlign": "middle",
                "position": "absolute",
                "top": "0",
                "left": "0"
              }
            }}></img>{`
        `}</picture>{`
    `}</span></p>
      <p><span parentName="p" {...{
          "className": "gatsby-resp-image-wrapper",
          "style": {
            "position": "relative",
            "display": "block",
            "marginLeft": "auto",
            "marginRight": "auto",
            "maxWidth": "1200px"
          }
        }}>{`
      `}<span parentName="span" {...{
            "className": "gatsby-resp-image-background-image",
            "style": {
              "paddingBottom": "66.66666666666666%",
              "position": "relative",
              "bottom": "0",
              "left": "0",
              "backgroundImage": "url('data:image/jpeg;base64,/9j/2wBDABALDA4MChAODQ4SERATGCgaGBYWGDEjJR0oOjM9PDkzODdASFxOQERXRTc4UG1RV19iZ2hnPk1xeXBkeFxlZ2P/2wBDARESEhgVGC8aGi9jQjhCY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2P/wgARCAANABQDASIAAhEBAxEB/8QAFwABAQEBAAAAAAAAAAAAAAAAAgADBP/EABYBAQEBAAAAAAAAAAAAAAAAAAEAAv/aAAwDAQACEAMQAAAB4WBh1hF//8QAGhAAAgIDAAAAAAAAAAAAAAAAAAECEhATMf/aAAgBAQABBQK0kbZFIPDRw//EABYRAAMAAAAAAAAAAAAAAAAAAAEQEf/aAAgBAwEBPwGBf//EABURAQEAAAAAAAAAAAAAAAAAAAAS/9oACAECAQE/AaU//8QAGRAAAgMBAAAAAAAAAAAAAAAAACEBESAy/9oACAEBAAY/AuikOcf/xAAcEAABBAMBAAAAAAAAAAAAAAAAAREhMVGBkaH/2gAIAQEAAT8hbz4EaluXJC7E+mCH/9oADAMBAAIAAwAAABAYH//EABcRAQADAAAAAAAAAAAAAAAAAAABEVH/2gAIAQMBAT8QjBb/xAAXEQEBAQEAAAAAAAAAAAAAAAABABEx/9oACAECAQE/EBPW1f/EABwQAQEAAgIDAAAAAAAAAAAAAAERACExgVFhkf/aAAgBAQABPxBgjfPP7kJMD6TA8lcyt7wNTYw1TlHRhNCBn//Z')",
              "backgroundSize": "cover",
              "display": "block"
            }
          }}></span>{`
  `}<picture parentName="span">{`
          `}<source parentName="picture" {...{
              "srcSet": ["/static/84cd71194bb9b29d25e45be4d0c38fa3/e93cc/IMG_7521.webp 300w", "/static/84cd71194bb9b29d25e45be4d0c38fa3/b0544/IMG_7521.webp 600w", "/static/84cd71194bb9b29d25e45be4d0c38fa3/68fc1/IMG_7521.webp 1200w", "/static/84cd71194bb9b29d25e45be4d0c38fa3/a2303/IMG_7521.webp 1800w", "/static/84cd71194bb9b29d25e45be4d0c38fa3/4293a/IMG_7521.webp 2400w", "/static/84cd71194bb9b29d25e45be4d0c38fa3/45587/IMG_7521.webp 5616w"],
              "sizes": "(max-width: 1200px) 100vw, 1200px",
              "type": "image/webp"
            }}></source>{`
          `}<source parentName="picture" {...{
              "srcSet": ["/static/84cd71194bb9b29d25e45be4d0c38fa3/73b64/IMG_7521.jpg 300w", "/static/84cd71194bb9b29d25e45be4d0c38fa3/3ad8d/IMG_7521.jpg 600w", "/static/84cd71194bb9b29d25e45be4d0c38fa3/6c738/IMG_7521.jpg 1200w", "/static/84cd71194bb9b29d25e45be4d0c38fa3/8b34c/IMG_7521.jpg 1800w", "/static/84cd71194bb9b29d25e45be4d0c38fa3/111a0/IMG_7521.jpg 2400w", "/static/84cd71194bb9b29d25e45be4d0c38fa3/a8943/IMG_7521.jpg 5616w"],
              "sizes": "(max-width: 1200px) 100vw, 1200px",
              "type": "image/jpeg"
            }}></source>{`
          `}<img parentName="picture" {...{
              "className": "gatsby-resp-image-image",
              "src": "/static/84cd71194bb9b29d25e45be4d0c38fa3/6c738/IMG_7521.jpg",
              "alt": "IMG 7521",
              "title": "IMG 7521",
              "loading": "lazy",
              "decoding": "async",
              "style": {
                "width": "100%",
                "height": "100%",
                "margin": "0",
                "verticalAlign": "middle",
                "position": "absolute",
                "top": "0",
                "left": "0"
              }
            }}></img>{`
        `}</picture>{`
    `}</span></p>
      <div style={{
        display: 'flex',
        flexWrap: 'wrap',
        justifyContent: 'space-between',
        margin: '0 -10px'
      }}>
	<div style={{
          flexGrow: 1,
          minWidth: 326,
          maxWidth: 540,
          margin: '0 10px'
        }}>
		<InstagramEmbed url="https://www.instagram.com/reel/C9aPzj1KvB4/" width="100%" mdxType="InstagramEmbed" />
	</div>
	<div style={{
          flexGrow: 1,
          minWidth: 326,
          maxWidth: 540,
          margin: '0 10px'
        }}>
		<InstagramEmbed url="https://www.instagram.com/reel/C9rceRMIFgA/" width="100%" mdxType="InstagramEmbed" />
	</div>
      </div>
    </Content>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      